module.exports = [{
      plugin: require('/Users/garrett/repos/portfolio/node_modules/gatsby-remark-images/gatsby-browser'),
      options: {"plugins":[],"maxWidth":1080,"wrapperStyle":"margin-bottom:60px;margin-top:30px; text-decoration: none; border: 0 none; ","showCaptions":true},
    },{
      plugin: require('/Users/garrett/repos/portfolio/node_modules/gatsby-plugin-catch-links/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/garrett/repos/portfolio/node_modules/gatsby-plugin-layout/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/garrett/repos/portfolio/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
