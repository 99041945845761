// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-markdown-template-js": () => import("/Users/garrett/repos/portfolio/src/templates/markdownTemplate.js" /* webpackChunkName: "component---src-templates-markdown-template-js" */),
  "component---src-pages-404-js": () => import("/Users/garrett/repos/portfolio/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-experiments-js": () => import("/Users/garrett/repos/portfolio/src/pages/experiments.js" /* webpackChunkName: "component---src-pages-experiments-js" */),
  "component---src-pages-index-js": () => import("/Users/garrett/repos/portfolio/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inquiry-js": () => import("/Users/garrett/repos/portfolio/src/pages/inquiry.js" /* webpackChunkName: "component---src-pages-inquiry-js" */),
  "component---src-pages-other-js": () => import("/Users/garrett/repos/portfolio/src/pages/other.js" /* webpackChunkName: "component---src-pages-other-js" */),
  "component---src-pages-projects-js": () => import("/Users/garrett/repos/portfolio/src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-thoughts-js": () => import("/Users/garrett/repos/portfolio/src/pages/thoughts.js" /* webpackChunkName: "component---src-pages-thoughts-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/garrett/repos/portfolio/.cache/data.json")

